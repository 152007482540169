@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');

.containerWrapper {
  font-family: 'Koulen', cursive;
  width: 100%;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;


  .mintContainer {
    width: 100%;
    min-height: 420px;
    margin-bottom: 10rem;
    padding: 32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    transition: 0.7s ease all;
    @media (max-width: 992px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }


  .mintWallets {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mintInfo {
    color: white;
    height: 100px;
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    font-family: 'Koulen', cursive;
    font-size: 30px;
    padding: 0 5rem;
    width: 300px;

  }

  .mintInput {
    margin-bottom: 1rem;
  }

  .connectWalletButton {
    background: rgba(0, 0, 0, 1);
    font-size: 30px;
    letter-spacing: 6px;
    font-family: 'Koulen', cursive;

    color: white;
    padding: 1.5rem 0;
    border: 1px solid orangered;
    border-radius: 3rem;
    box-shadow: 0px 0px 12px 0px orangered;
    transition: 0.7s all;
    width: 360px;
    text-align: center;

    @media (max-width: 1200px) {
      margin: 0 !important;
    }
  }

  .mobileMetamaskButton {
    background: rgba(0, 0, 0, 1);
    font-size: 50px;
    font-weight: normal;
    font-family: 'Koulen', cursive;
    color: white;
    padding: 2rem 2rem;
    border: 1px solid orangered;
    margin-bottom: 1rem !important;
    border-radius: 3rem;
    box-shadow: 0px 0px 12px 0px orangered;
    transition: 0.7s all;

    text-align: center;
    margin-right: 16px;
    @media (max-width: 992px) {
      margin-right: 0 !important;
    }
  }

  .connectWalletButton:hover {
    cursor: pointer;
    box-shadow: 0px 0px 32px 0px orangered;
    transition: 0.7s all;
  }

  .mintInnerContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0rem;
    border-radius: 5px;


  }

  .mintContainerMint {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;

  }

  .walletAddressInfo {
    padding: 8px;
    margin: 15px 0 15px 0;
    width: 100%;

    p {
      color: white;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: 'Sohne-breit-halbfett', serif;
    }
  }
}

.mintConnectWallet {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100vh;
}

@media (max-width: 480px) {
  .connectWalletButton {
    display: none;
  }
  .mobileMetamaskButton {
    background: none;
    font-size: 20px !important;
    letter-spacing: 1px !important;
    font-family: 'Sohne-breit-halbfett', serif;
    color: #ffffff;
    padding: 1rem 2rem !important;
    border: 1px solid orangered;
    margin-bottom: 0 !important;
    border-radius: 3rem;
    box-shadow: 0px 0px 12px 0px orangered;
    transition: 0.7s all;

    text-align: center;
    margin-right: 16px;
  }
  .mobileMetamaskButton:hover {
    cursor: pointer;
    box-shadow: 0px 0px 32px 0px orangered;
    transition: 0.7s all;
  }
}

.mintButton {
  background: none;
  font-size: 20px !important;
  letter-spacing: 1px !important;
  font-family: 'Sohne-breit-halbfett', serif;
  color: #ffffff;
  padding: 1rem 2rem !important;
  border: 1px solid orangered;
  margin: 1rem 0 2rem 0 !important;
  border-radius: .5rem;
  cursor: pointer;
  transition: 0.7s all;
  width: 205px;
  text-align: center;
}

.mintButton:hover {
  box-shadow: 0 0 30px 4px rgba(255, 69, 0, 1);
  background-color: orangered;
  color: black;
}

@media (min-width: 480px) {
  .mobileMetamaskButton {
    display: none;
  }
}

.mobileMint {
  display: none;
  @media (max-width: 1200px) {
    display: flex;
  }
}

.projectCardOuterContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  border-radius: 5px;
  width: 100%;
  margin-top: 250px;
  @media (max-width: 1200px) {
    display: none;
  }

}


.projectCardContainer {
  border: 1px solid red;
  border-radius: 20px;
  position: relative;
  box-shadow: 0px 0px 12px 0px #FF0E0E;
  width: 75%;
  max-height: 60vh;
  background-color: rgba(0, 0, 0, .5);
  @media (max-width: 1246px) {
    max-height: 50vh;
  }
}

.mintLoadingButton {
  background: none;
  font-size: 20px !important;
  letter-spacing: 1px !important;
  font-family: 'Sohne-breit-halbfett', serif;
  color: #ffffff;
  padding: 1rem 2rem !important;
  border: 1px solid orangered;
  margin-bottom: 1rem !important;
  border-radius: .5rem;
  cursor: pointer;
  transition: 0.7s all;
  width: 205px;
  text-align: center;
}

.mintLoadingButton:hover {
  cursor: not-allowed;
}

.innerContainer {
  display: flex;
}

.projectCardDescription {

}

.projectCardLeft {
  z-index: 1;
  position: relative;

  cursor: pointer;
  @media (max-width: 1246px) {
    width: 50%;
  }
}


.projectCardLeftImg {

  cursor: default;
  width: auto;

  max-height: 60vh;
  border-radius: 20px 0 0 20px;
  @media (max-width: 1246px) {
    max-height: 50vh;
  }
}

.projectCardRight {
  z-index: 10;
  position: relative;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.projectCardRightTop {

  text-align: center;
  margin-bottom: -2rem;

}

.projectCardRightCenter {
  padding-top: 1.5rem;
  border-top: 1px solid rgba(152, 160, 181, .3);
}

.projectCardRightTop p {
  font-family: 'Sohne-breit-halbfett', sans-serif;
  color: white;
  font-size: 2rem;
  width: 95%;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

}

.projectCardSocials {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.projectCardSocials a:not(:last-child) {
  margin-right: 1rem;
}

.projectCardRightTop p:nth-child(4) {
  font-family: "TT Norms Pro Regular", sans-serif;
  color: #98A0B5;
  font-size: .9rem;

}

.projectCardRightTop p:nth-child(5) {
  font-family: "TT Norms Pro Regular", sans-serif;
  color: #98A0B5;
  font-size: .9rem;

}

.projectCardAttributeContainer {
  margin-bottom: 1rem;
}

.projectCardAttribute {
  display: flex;

}

.projectCardAttribute p:first-child {
  font-family: "TT Norms Pro Regular", sans-serif;
  font-size: 1rem;
  background: -webkit-linear-gradient(#FF0E0E, #FB5300);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 40%;
}

.projectCardAttribute p:last-child {
  font-family: 'Sohne-breit-halbfett', sans-serif;
  color: white;
  margin-left: 1rem;
}

.borderImageFirst {
  position: absolute;
  top: -23px;
  z-index: 100;
}

.borderImageSecond {
  position: absolute;
  bottom: -23px;
  right: 0;
  z-index: 100;
}

.projectCardRightBottom {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  text-align: center;
  position: absolute;
  right: 20px !important;
  top: 20px;
  z-index: 120;

}

.projectCardRightBottom p {
  font-family: 'Sohne-breit-halbfett', sans-serif;
  animation-name: pulseOngoing;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  background: -webkit-linear-gradient(#FF0E0E, #FB5300);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 2rem;
  margin: 0 !important;
  padding: .5rem 1rem;
  font-weight: bold;
  transition: 0.3s all;
  text-transform: uppercase;
}

.projectCardRightBottom p:hover {

  cursor: pointer;
  box-shadow: 0px 0px 12px 0px #FF0E0E;
}

.projectCardRightBottomContainer {
  border-top: 1px solid rgba(152, 160, 181, .3);
  display: flex;
  justify-content: start;
  padding-top: 1rem;
}

.projectCardRightBottomContainer a {

  border-radius: 25px;
  padding: .5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 0px 12px 0px #FF0E0E;
  transition: all .6s;
}

.projectCardRightBottomContainer a:hover {
  box-shadow: 0px 0px 25px 0px #FF0E0E;
  transition: all .6s;
}

.projectCardRightBottomContainer p {
  background: -webkit-linear-gradient(#FF0E0E, #FB5300);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.1rem;
  margin: 0 !important;


}

.projectCardRightBottomContainer a:hover {
  cursor: pointer;
}

.projectCardRightBottomContainer a {
  text-decoration: none;

}

@media (max-width: 1200px) {
  .projectCardRightTop {
    margin: 0 !important;
  }

  .projectCardRightBottom {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    text-align: center;
    position: absolute;
    right: 10px !important;
    top: -50px;
  }

  .innerContainer {
    flex-direction: column;
  }

  .projectCardAttribute {
    justify-content: space-between;

  }

  .projectCardAttributeContainer {
    margin-top: 1rem;
    margin-bottom: .8rem;
  }

  .projectCardRightBottomContainer {
    justify-content: center;
  }

  .projectCardLeft {
    width: 100% !important;
  }
  .projectCardLeft a {

  }
  .projectCardLeft img {

    width: 100%;
    height: 100%;
    border-radius: 20px 20px 0 0;
  }

  .projectCardRightBottom p {
    box-shadow: 0px 0px 22px 0px #FF0E0E;
  }

  .projectCardRightTop p {
    font-size: 1.5rem;
    text-align: center;
  }

  .projectCardSocials {
    display: flex;
    justify-content: center;
  }

  .projectCardDescription {
    text-align: center;
  }
}

.modal-content {

  background-size: cover;
  opacity: 1;
}

@keyframes pulseOngoing {
  0% {
    box-shadow: 0px 0px 32px 0px #FF0E0E;
    transform: scale(1);
  }
  50% {
    box-shadow: 0px 0px 12px 0px #FF0E0E;
    transform: scale(1.05);
  }
  100% {
    box-shadow: 0px 0px 32px 0px #FF0E0E;
    transform: scale(1);
  }
}
