@font-face {
  font-family: 'Sohne-breit-buch';
  src: url("./fonts/SohneBreit-Buch.otf");
}

@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');
@font-face {
  font-family: 'Sohne-breit-halbfett';
  src: url("./fonts/SohneBreit-Halbfett.otf");
}

@font-face {
  font-family: 'TT Norms Pro Regular';
  src: url("./fonts/TT Norms Pro Regular.otf");
}


video {
  pointer-events: none;
}

.mainContainer {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  @media (max-width: 992px) {
    overflow: hidden;
  }

}

#WEB3_CONNECT_MODAL_ID > div {
  font-family: 'Koulen', cursive !important;
}

.innerContainer {
  position: relative;
  z-index: 1;
  background-color: rgba(0, 0, 0, .5);
  height: 100%;
}

.videoBackground {
  position: fixed;
  z-index: -1;
  background-color: black;
  width: 100vw;
  height: 100vh;

}

.video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  @media (max-width: 992px) {
    display: none;
  }
}
