.navbarWrapper {
  padding: 0 3rem;

}

.navbarContainer {
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

.navbarLeft {
  @media (max-width: 992px) {
    margin-top: 1rem;
  }
}

.navbarLink {
  font-family: 'TT Norms Pro Regular', serif;
  @media (max-width: 992px) {
    margin-top: 1rem;
  }
}

.navbarCenter {
  z-index: 999;
  display: flex;
  align-items: center;
  margin-left: 3rem;
  @media (max-width: 992px) {
    margin-top: 1rem;
  }

  & > :not(:last-child) {
    margin-right: 16px;
  }
}

.navbarCenter img {
  height: 25px;
  width: auto;
  cursor: pointer;
}

.navbarRight {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.navbarRight a {
  text-decoration: none;
  color: white;
}
