@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');

.counter {
  font-family: 'Koulen', cursive;
  background: none;
  width: 9.125rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .5rem;
  color: white;


  transition: 0.7s ease all;

}



.button {
  font-family: 'Koulen', cursive;
  user-select: none;
  cursor: pointer;
  font-size: 2.5rem;
  width: 12rem;
  text-align: center;
}

.buttonLeft {

}

.buttonRight {

}

.count {
  font-family: 'Koulen', cursive;
  user-select: none;
  font-size: 2.5rem;

  > input {
    width: 100%;
    color: white;
    font-weight: 400;
    text-align: center;

    border: 0;
    outline: 0 !important;
    background: transparent;
    appearance: none !important;
  }
}

@media (hover: hover) {
  .button:hover {
    color: red;
  }
}
